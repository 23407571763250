import React, { useEffect, useState } from "react";
import loadTwitterEmbed from "../scripts/twitterEmbed.js";

const EmbedTwitter = (props) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    loadTwitterEmbed(() => {
      setLoaded(true);
    });
  });
  return (
    <div className="twitterEmbed">
      {loaded ? (
        <a
          className="twitter-timeline"
          href="https://twitter.com/TCUKchaplains?ref_src=twsrc%5Etfw"
          data-tweet-limit="3"
        >
          Tweets by TCUKchaplains
        </a>
      ) : (
        ""
      )}
    </div>
  );
};
export default EmbedTwitter;
