/** @jsx jsx */
import { Box, jsx } from "theme-ui";
//import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const BlockQuote = ({ data }) => {
  const image = getImage(data.image);
  return (
    <Box sx={styles}>
      <Box className="quote">
        <div>
          <FaQuoteLeft />
          <blockquote>{data.quote}</blockquote>
          <FaQuoteRight className="end" />
          <cite>{data.author}</cite>
        </div>
      </Box>
      <GatsbyImage
        image={image}
        className="quoteImage"
        alt={data.image?.alt ? data.image.alt : ""}
      />
    </Box>
  );
};

export default BlockQuote;

const styles = {
  display: ["block", "flex"],
  my: [3],
  "& .quote": {
    display: "flex",
    alignItems: "center",
    bg: "house1",
    width: ["100%", "50%"],
    color: "white",
    mr: [0, "3px"],
    p: 4,
    fontSize: [3, 4],
    blockquote: {
      fontSize: [4, 5],
      my: [1, 2],
      mx: [2, 3],
      textAlign: "center",
      fontWeight: "light",
    },
    "& > div": {
      position: "relative",
    },
    svg: {
      opacity: 0.4,
      fontSize: 5,
      "&.end": {
        position: "absolute",
        right: 0,
      },
    },
    cite: {
      mt: [4, "40px"],
      textAlign: ["center"],
      width: "100%",
      display: "block",
    },
  },
  "& .quoteImage": {
    width: ["100%", "50%"],
    ml: [0, "3px"],
  },
};
