/** @jsx jsx */
import { Box, jsx } from "theme-ui";
//import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlockPrayer = ({ data }) => {
  const image = getImage(data.backgroundImage);
  return (
    <Box sx={styles}>
      <GatsbyImage
        image={image}
        // style={{ gridArea: "1/1" }}
        alt="" //presentational
      />
      <Box
        className="prayerContents"
        // style={{
        //   gridArea: "1/1",
        //   position: "relative",
        //   placeItems: "center",
        //   display: "grid",
        // }}
      >
        <div>
          <h2>{data.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: data.body.html }} />
        </div>
        {data.audioFile && (
          <figure>
            <audio controls src={data.audioFile.url}>
              Your browser does not support the
              <code>audio</code> element.
            </audio>
            <figcaption>
              Beatitudes For The Theatre Industry - read by Janie Dee
            </figcaption>
          </figure>
        )}
      </Box>
    </Box>
  );
};

export default BlockPrayer;

const styles = {
  display: "grid",
  my: [3],
  "& > div": {
    gridArea: "1/1",
    "&.prayerContents": {
      position: "relative",
      placeItems: "center",
      display: "grid",
      color: "white",
      px: [2, 3],
      h2: {
        backgroundColor: "house2",
        px: 2,
        display: "inline-block",
      },
      div: {
        fontSize: 4,
        textAlign: "center",
      },
      audio: {
        filter: "brightness(0.4) sepia(1) hue-rotate(268deg) saturate(3)",
      },
      figure: {
        textAlign: "center",
      },
    },
    "&.gatsby-image-wrapper": {
      filter: "brightness(0.2) sepia(1) hue-rotate(268deg) saturate(3)",
    },
  },
};
