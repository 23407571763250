/** @jsx jsx */
import React from "react";
import { Box, jsx } from "theme-ui";
import EmbedFacebook from "./EmbedFacebook";
import EmbedTwitter from "./EmbedTwitter";
import { FaTwitter, FaFacebookF, FaYoutube, FaInstagram } from "react-icons/fa";

const SocialFeeds = ({ socials }) => {
  return (
    <Box sx={styles}>
      <h2>Social Feeds</h2>
      <Box className="socialFeeds">
        <EmbedFacebook />
        <EmbedTwitter />
      </Box>
      <Box className="socialLinks">
        <p>Follow us on Social Media:</p>
        <a href={socials.twitter}>
          <FaTwitter />
        </a>
        <a href={socials.facebook}>
          <FaFacebookF />
        </a>
        <a href={socials.youtube}>
          <FaYoutube />
        </a>
        <a href={socials.instagram}>
          <FaInstagram />
        </a>
      </Box>
    </Box>
  );
};

export default SocialFeeds;

const styles = {
  h2: {
    fontSize: 5,
    textAlign: "center",
  },
  "& .socialFeeds": {
    display: ["block", "block", "flex"],
    mx: [3, 5],
    "& > div": {
      width: ["100%", "100%", "50%"],
    },
    "& .twitterEmbed": {
      display: "flex",
      py: 4,
      justifyContent: "center",
      bg: "#1DA1F2", //twitter blue
      "& .twitter-timeline": {
        maxWidth: "80% !important",
        //maxHeight: "580px",
      },
    },
    "& .facebookEmbed": {
      bg: "#4267B2", //facebook blue
      display: "flex",
      justifyContent: "center",

      py: 4,
    },
  },
  "& .socialLinks": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "house4",
    a: {
      fontSize: 4,
      width: "50px",
      height: "50px",
      mx: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "house4",
    },
    p: {
      fontSize: 3,
      mx: 2,
      bg: "house4",
      color: "white",
      px: 2,
    },
  },
};
