/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import BlockText from "../components/BlockText";
import BlockQuote from "../components/BlockQuote";
import BlockPrayer from "../components/BlockPrayer";
import EventsGrid from "../components/EventsGrid";
import NewsGrid from "../components/NewsGrid";
import SEO from "../components/Seo";

import SocialFeeds from "../components/SocialFeeds";

// markup
const IndexPage = ({ data }) => {
  const bannerImage = getImage(data.graphCmsPagesHome.blockBanner.image);
  const blocks = data.graphCmsPagesHome.homeBlocks;

  const events = data.allGraphCmsEvent.nodes;
  const news = data.allGraphCmsNewsItem.nodes;
  //filter events
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  //futureevents
  const futureEvents = events.filter((item) => {
    //compare with endDate if regular
    if (item.regularEvent) {
      return item.endDate >= today;
    } else {
      return item.date >= today;
    }
  });
  console.log(events.nodes);

  return (
    <Layout>
      <SEO title="Home" />
      <GatsbyImage image={bannerImage} alt="" />

      {blocks.map((block, index) => {
        switch (block.remoteTypeName) {
          case "BlockText":
            return (
              <BlockText data={block} pageTitle key={`homeblock${index}`} />
            );
          case "BlockQuote":
            return <BlockQuote data={block} key={`homeblock${index}`} />;
          case "BlockPrayer":
            return <BlockPrayer data={block} key={`homeblock${index}`} />;
          case "BlockSocialFeed":
            return (
              <SocialFeeds
                socials={data.graphCmsPagesContact}
                key={`homeblock${index}`}
              />
            );
          default:
            return <></>;
        }
      })}
      {/* <HomeTheatres data={theatres} /> */}
      <EventsGrid events={futureEvents} />
      <NewsGrid news={news} />
    </Layout>
  );
};

export const query = graphql`
  query HomepageQuery {
    graphCmsPagesHome(
      id: { eq: "PagesHome:ckruro6g0ed3z0c59t3121esk:PUBLISHED" }
    ) {
      id
      title
      remoteTypeName
      blockBanner {
        text
        id
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: DOMINANT_COLOR
            aspectRatio: 1.97
          )
        }
      }
      homeBlocks {
        ... on GraphCMS_BlockBiog {
          id
          name
          remoteTypeName
        }
        ... on GraphCMS_BlockPrayer {
          id
          remoteTypeName
          audioFile {
            fileName
            url
          }
          backgroundImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              aspectRatio: 1.97
              placeholder: DOMINANT_COLOR
            )
            fileName
          }
          body {
            html
            raw
          }
          title
        }
        ... on GraphCMS_BlockQuote {
          id
          remoteTypeName
          author
          quote
          image {
            alt
            fileName
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.33
            )
          }
        }
        ... on GraphCMS_BlockText {
          id
          remoteTypeName
          title
          text {
            raw
            html
          }
        }
        ... on GraphCMS_BlockSocialFeed {
          id
          remoteTypeName
          socialTitle: title
        }
      }
    }
    allGraphCmsEvent(sort: { order: ASC, fields: date }) {
      nodes {
        title
        slug
        date
        endDate
        regularEvent
        regularEventDay
        featuredImage {
          gatsbyImageData(layout: CONSTRAINED)
          alt
        }
      }
    }
    allGraphCmsNewsItem {
      nodes {
        date
        slug
        title
        featuredImage {
          gatsbyImageData(layout: CONSTRAINED)
          alt
        }
      }
    }
    graphCmsPagesContact(
      id: { eq: "PagesContact:cksj3wi4g15jy0b64l2adf03h:PUBLISHED" }
    ) {
      contactAddress {
        raw
        html
      }
      contactTelephone
      contactEmail
      twitter
      facebook
      instagram
      youtube
    }
  }
`;

export default IndexPage;
